import React, { useEffect, useState } from "react"
import { Spin, Button as AntButton } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { BaseButtonProps } from "antd/lib/button/button"

export interface ButtonProps extends BaseButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  label: string
  className?: string
  fontSize?: number
  style?: object
}

const Button: React.ComponentType<ButtonProps> = (props: any) => {
  const { label, loading, className, fontSize } = props
  const [compClass, setCompClass] = useState("submit-button w-button")
  const antIcon = <LoadingOutlined style={{ fontSize: fontSize }} spin />

  useEffect(() => {
    if (className) {
      setCompClass(compClass + " " + className)
    }
  }, [])

  return (
    // <button {...props}
    //   disabled={loading ? true : false}
    //   className={compClass}>
    //   {loading && <Spin indicator={antIcon}/>} {label}
    // </button>
    <AntButton
      {...props}
      className={props.className}
      size={props.size ? props.size : "large"}
      loading={loading}
      type={props.type ? props.type : "primary"}
    >
      {label}
    </AntButton>
  )
}

export default Button
