import React, { useContext, useState } from "react";
import Modal from "@/components/common/Modal";
import Button from "@/components/common/Button";
import ProjectClient from "@/clients/ProjectClient";
import TemplateClient from "@/clients/TemplateClient";
import { message, Upload, Button as AntButton, Spin } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { AppDispatchContext } from "@/components/context/AppContext";
import { Actions } from "../context/AppReducer";
import JWTHelper from "@/utilities/JWTHelper";
import { UploadOutlined } from "@ant-design/icons";

interface CreateCollectionModalProps {
  project: any;
  show: boolean;
  onClose: any;
  onCreate: any;
}

const CreateCollectionModal: React.ComponentType<
  CreateCollectionModalProps
> = ({ project, show, onClose, onCreate }) => {
  const projectClient = new ProjectClient();
  const templateClient = new TemplateClient();
  const [collectionName, setCollectionName] = useState("");
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(AppDispatchContext);
  // const [uploadedFile, setUploadedFile] = useState("");
  const [fileList, setFilelist] = useState<Array<any>>([]);
  const [links, setLinks] = useState<Array<string>>([]);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 14, color: "#ffffff" }} spin />
  );

  const createCollection = () => {
    if (fileList.length <= 0) {
      message.error("Please upload a csv file containing page links.");
      return;
    }

    setLoading(true);
    templateClient.createTemplate(project.id, { name: collectionName }).then(
      (collection: any) => {
        templateClient.saveLinks(collection.id, links).then(
          (res: any) => {
            onCreate(collection);
            setCollectionName("");
            setLoading(false);
          },
          (error: string) => {
            message.error(error);
            setLoading(false);
          }
        );
      },
      (error: string) => {
        message.error(error);
        setLoading(false);
      }
    );
  };

  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    console.log(info);
    setFilelist([info.file]);
    if (info.file.status === "done") {
      // console.log(info.file);
      // setUploadedFile(info.file.name);
      setLinks(info.file.response);
      dispatch({ type: Actions.UPDATE_LINKS, payload: info.file.response });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Modal show={show} onClose={onClose}>
      <div className="margin-bottom-40">
        <h2 className="page-heading">Create Collection</h2>
      </div>
      <div className="popup-modal-form-block w-form">
        <form
          id="start-new-project"
          name="wf-form-Start-new-project"
          data-name="Start new project"
          method="get"
        >
          <div className="">
            <label className="popup-modal-field-label">Collection Name</label>
            <input
              type="text"
              className="popup-modal-input-field w-input"
              name="Project-Name"
              value={collectionName}
              onChange={(e) => setCollectionName(e.target.value)}
              data-name="Project Name"
              placeholder="Enter project name"
              id="Project-Name"
            />
          </div>

          <div className="upload-container margin-bottom-30">
            <Upload
              action={`${process.env.REACT_APP_API_BASE_URL}/upload/csv`}
              headers={{
                Authorization: `Bearer ${JWTHelper.getAccessToken()}`,
              }}
              fileList={fileList}
              name="file"
              className="csv-uploader"
              accept=".csv"
              // showUploadList={false}
              onChange={handleChange}
            >
              <AntButton icon={<UploadOutlined />}>Upload CSV File</AntButton>
            </Upload>
          </div>

          {/* <Button
            label="Create Collection"
            fontSize={24}
            loading={loading}
            onClick={createCollection}
          /> */}
          <a
            href="#"
            className="main-button w-button create-project"
            onClick={createCollection}
          >
            Create Collection {loading && <Spin indicator={antIcon} />}
          </a>
        </form>
      </div>
    </Modal>
  );
};

export default CreateCollectionModal;
