import BaseClient from "./BaseClient";

class Project extends BaseClient {
  getProject = (id: any) => {
    return this.get(`/project/${id}`);
  }

  getProjects = (params?: any) => {
    return this.get(`/project`, { params });
  }

  createProject = (data: any) => {
    return this.post(`/project`, data);
  }

  updateProject = (id: number, data: any) => {
    return this.put(`/project/${id}`, data);
  }

  deleteProject = (id: number) => {
    return this.delete(`/project/${id}`);
  }
}

export default Project;