import BaseClient from "./BaseClient";

class UserClient extends BaseClient {
  login = (username: string, password: string) => {
    return this.post("/login", { username, password });
  }

  getCurrentUser = () => {
    return this.get("/me");
  }
}

export default UserClient;