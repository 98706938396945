import React from "react";
import Modal from "@/components/common/Modal";
import { CheckOutlined } from "@ant-design/icons";

interface GuideModalProps {
  show: boolean;
  onClose: any;
}

const GuideModal: React.ComponentType<GuideModalProps> = ({
  show,
  onClose,
}) => {
  const guides = [
    { text: "*", css: true, xpath: true },
    { text: "E", css: true, xpath: true },
    { text: "E[foo]", css: true, xpath: true },
    { text: 'E[foo="bar"]', css: true, xpath: true },
    { text: 'E[foo~="bar"]', css: true, xpath: true },
    { text: 'E[foo~="bar"]', css: true, xpath: true },
    { text: 'E[foo$="bar"]', css: true, xpath: true },
    { text: 'E[foo*="bar"]', css: true, xpath: true },
    { text: 'E[foo|="bar"]', css: true, xpath: true },
    { text: "E:root", css: true, xpath: true },
    { text: "E:nth-last-child(42)", css: true, xpath: true },
    { text: "E:nth-child(42)", css: true, xpath: true },
    { text: "E:nth-of-type(42)", css: true, xpath: true },
    { text: "E:nth-last-of-type(42)", css: true, xpath: true },
    { text: "E:first-child", css: true, xpath: true },
    { text: "E:last-child", css: true, xpath: true },
    { text: "E:first-of-type", css: true, xpath: true },
    { text: "E:last-of-type", css: true, xpath: true },
    { text: "E:only-child", css: true, xpath: true },
    { text: "E:only-of-type", css: true, xpath: true },
    { text: "E:empty", css: true, xpath: true },
  ];
  return (
    <Modal show={show} onClose={onClose}>
      <div className="margin-bottom-40">
        <h2 className="page-heading">Selector Guide</h2>
      </div>
      <div
        className="popup-modal-form-block"
        style={{ height: 500, width: 700 }}
      >
        <div style={{ overflow: "auto", height: 500 }}>
          <div className="repository-grid-wrapper source-grid ">
            <div className="repository-grid-navigation-wrapper guide-wrapper">
              <div className="repository-name-wrapper-w-padding">
                <div className="page-grid-text">Selector</div>
              </div>
              <div className="page-grid-text">to CSS</div>
              <div className="page-grid-text">to XPath</div>
            </div>
          </div>
          {guides.map((guide: any, index: number) => {
            return (
              <div className={`repository-single-item-wrapper ` + (index % 2 === 0 ? "is-grey" : "")}>
                <div className="repository-name-wrapper-w-padding">
                  <div className="page-grid-item-text">{guide.text}</div>
                </div>
                <div className="repository-name-wrapper">
                  <div className="page-grid-item-text">
                    {guide.css && <CheckOutlined />}
                  </div>
                </div>
                <div className="repository-name-wrapper">
                  <div className="page-grid-item-text">
                    {guide.xpath && <CheckOutlined />}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default GuideModal;
