import React, { useEffect, useState } from "react";
import LinkList from "@/components/tools/LinkList";
import Tools from "@/components/tools/Tools";
import Content from "@/components/content/Page";
import Template from "@/clients/TemplateClient";
import ProjectClient from "@/clients/ProjectClient";
import ProjectItem from "../project/ProjectItem";
import CreateProjectModal from "../project/CreateProjectModal";
import UpdateProjectModal from "../project/UpdateProjectModal";
import DeleteProjectModal from "../project/DeleteProjectModal";
import { Button, message, Skeleton } from "antd";
import JWTHelper from "@/utilities/JWTHelper";
import Header from "../common/Header";
import { useNavigate } from "react-router-dom";
import grid from "@/resources/images/grid-1-1.svg";

const Home: React.ComponentType = () => {
  const templateClient = new Template();
  const projectClient = new ProjectClient();
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState<Array<any>>([]);
  const [showCreateProject, setShowCreateProject] = useState(false);
  const [showUpdateProject, setShowUpdateProject] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [projecdtToUpdate, setProjectToUpdate] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState<any>();

  useEffect(() => {
    if (!JWTHelper.isLogin()) {
      navigate("/login", { replace: true });
    } else {
      loadProjects();
    }
  }, []);

  const loadProjects = () => {
    setLoading(true);
    projectClient.getProjects().then(
      (res: any) => {
        setProjects(res.data);
        setLoading(false);
      },
      (error: string) => {
        message.error(error);
      }
    );
  };

  const updateProject = (project: any) => {
    setProjectToUpdate(project);
    setShowUpdateProject(true);
  };

  const onCreateProject = (project: any) => {
    projects.unshift(project);
    setProjects([...projects]);
    setShowCreateProject(false);
  };

  const onUpdateProject = (project: any) => {
    // console.log(project);
    let index = projects.findIndex((proj: any) => {
      return proj.id === project.id;
    });
    projects[index] = { ...project };
    setProjects([...projects]);
    setShowUpdateProject(false);
  };

  const deleteProject = (project: any) => {
    setProjectToDelete(project);
    setShowDeleteModal(true);
  };

  const onDeleteProject = () => {
    let index = projects.findIndex((proj: any) => {
      return proj.id === projectToDelete?.id!;
    });
    projects.splice(index, 1);
    setProjects([...projects]);
    setProjectToDelete(null);
    setShowDeleteModal(false);
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main">
        <div className="page-padding">
          <div className="container">
            <div className="padding-vertical-small">
              <div
                className="page-heading-wrapper"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h2 className="page-heading">Projects</h2>
                <a
                  href="#"
                  className="main-button w-button create-project"
                  onClick={() => setShowCreateProject(true)}
                >
                  {/* <img src={grid} loading="lazy" alt="" /> */}
                  New Project
                </a>
              </div>
              <div className="repository-grid-wrapper source-grid ">
                <Skeleton loading={loading}>
                  {projects.map((project: any, index: number) => {
                    return (
                      <ProjectItem
                        key={project.id}
                        updateProject={updateProject}
                        project={project}
                        setDeleteProject={deleteProject}
                      />
                    );
                  })}
                </Skeleton>
              </div>
              {/* <a href="#" className="add-source-link w-inline-block">
            <div>Add Source</div>
          </a> */}
            </div>
          </div>
          <CreateProjectModal
            show={showCreateProject}
            onClose={() => setShowCreateProject(false)}
            onCreate={onCreateProject}
          />
          <UpdateProjectModal
            show={showUpdateProject}
            onClose={() => setShowUpdateProject(false)}
            onUpdate={onUpdateProject}
            project={projecdtToUpdate}
          />

          <DeleteProjectModal
            show={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            project={projectToDelete!}
            onDelete={onDeleteProject}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
