import React from "react";
import moment from "moment";
import arrowUpRight from "@/resources/images/arrow-up-right-1-1.svg";
import closeIcon from "@/resources/images/close-icon.svg";
import { Link } from "react-router-dom";

interface TemplateItemProps {
  project: any;
  setDeleteProject: any;
  updateProject: any
}

const TemplateItem: React.ComponentType<TemplateItemProps> = (props) => {
  const { project, setDeleteProject, updateProject } = props;
  const baseUrl = process.env.REACT_APP_BASE_URL;

  return (
    <div className="project-overview-card-wrapper" key={project.id}>
      <div className="project-overview-card-left">
        <div className="project-overview-image-wrapper">
          {project.thumbnail && (
            <img
              src={baseUrl + project.thumbnail}
              loading="lazy"
              sizes="(max-width: 479px) 85vw, (max-width: 767px) 91vw, (max-width: 991px) 76vw, 100vw"
              width="600"
              alt=""
              className="object-fit-cover"
            />
          )}
          {!project.thumbnail && (
            <img
              src={require("@/resources/images/project_default.png")}
              loading="lazy"
              sizes="(max-width: 479px) 85vw, (max-width: 767px) 91vw, (max-width: 991px) 76vw, 100vw"
              width="600"
              alt=""
              className="object-fit-cover"
            />
          )}
        </div>
      </div>
      <div className="project-overview-card-right">
        <div className="project-overview-name-text">{project.name}</div>
        <div className="project-overview-single-item">
          <div className="text-medium"># of collections</div>
          <div className="text-light">{project.collections}</div>
        </div>
        <div className="project-overview-single-item">
          <div className="text-medium"># of pages</div>
          <div className="text-light">{project.pages}</div>
        </div>
        <div className="project-overview-single-item" style={{border: "none"}}>
          <div className="text-medium">Created</div>
          <div className="text-light">
            {moment(project.created_at).fromNow()}
          </div>
        </div>
        <div className="buttons">
          <Link
            to={`project/${project.id}/collections`}
            className="main-button w-button"
          >
            Manage Project
          </Link>
          <a
          href="#"
            onClick={() => updateProject(project)}
            className="main-button w-button update"
          >
            Update Project
          </a>
        </div>
      </div>
      <a
        href="#"
        className="link-block w-inline-block"
        onClick={() => setDeleteProject(project)}
      >
        <img src={closeIcon} loading="lazy" alt="" />
      </a>
    </div>
  );
};

export default TemplateItem;
