import BaseClient from "./BaseClient";

class TemplateClient extends BaseClient {
  getTemplate = (id: any) => {
    return this.get(`/template/${id}`);
  }

  getTemplates = (projectId: any, page: number, limit: number) => {
    return this.get(`/project/${projectId}/template`, { params: { page, limit }});
  }

  createTemplate = (projectId: any, data: any) => {
    return this.post(`/project/${projectId}/template`, data);
  }

  updateTemplate = (projectId: any, id: number, data: any) => {
    return this.put(`/project/${projectId}/template/${id}`, data);
  }

  deleteTemplate = (projectId: any, id: number) => {
    return this.delete(`/project/${projectId}/template/${id}`);
  }

  addLink = (templateId: number, link: string) => {
    let data = { link }
    return this.post(`/template/${templateId}/link`, data);
  }

  editLink = (templateId: number, linkId: number, link: string) => {
    let data = { link }
    return this.put(`/template/${templateId}/link/${linkId}`, data);
  }

  deleteLink = (templateId: number, linkId: number) => {
    return this.delete(`/template/${templateId}/link/${linkId}`);
  }

  getTemplateLinks = (templateId: any) => {
    return this.get(`/template/${templateId}/link`);
  }

  saveLinks = (templateId: any, links: Array<string>) => {
    return this.post(`/template/${templateId}/savelinks`, { links });
  }

  generateCSV = (templateId: any) => {
    return this.post(`/template/${templateId}/generate/csv`, {});
  }

  previewSelectors = (templateId: any, limit?: number, page?: number) => {
    let params = { limit, page }
    return this.get(`/template/${templateId}/preview`, { params });
  }
}

export default TemplateClient;