import React, { useEffect, useState, useContext } from "react";
import TemplateClient from "@/clients/TemplateClient";
import ProjectClient from "@/clients/ProjectClient";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Button, message, Skeleton, Pagination } from "antd";
import CollectionItem from "@/components/collections/CollectionItem";
import CreateCollectionModal from "@/components/collections/CreateCollectionModal";
import UpdateCollectionModal from "@/components/collections/UpdateCollectionModal";
import DeleteCollectionModal from "@/components//collections/DeleteCollectionModal";
import { AppStateContext, AppDispatchContext } from "../context/AppContext";
import { Actions } from "../context/AppReducer";
import Header from "../common/Header";

const Collections = () => {
  const templateClient = new TemplateClient();
  const projectClient = new ProjectClient();
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showCreateCollection, setShowCreateCollection] = useState(false);
  const [showUpdateCollection, setShowUpdateColleciton] = useState(false);
  const [showDeleteCollection, setShowDeleteCollection] = useState(false);
  const [collectionToUpdate, setCollectionToUpdate] = useState(null);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { state } = useContext(AppStateContext);
  const { dispatch } = useContext(AppDispatchContext);

  useEffect(() => {
    loadProject();
    loadTemplates();
  }, []);

  useEffect(() => {
    if (hasLoaded) loadTemplates();
  }, [currentPage]);

  useEffect(() => {
    if (hasLoaded) {
      setCurrentPage(1);
      loadTemplates();
    }
  }, [limit]);

  const loadProject = () => {
    projectClient.getProject(projectId).then(
      (project: any) => {
        dispatch({ type: Actions.UPDATE_PROJECT, payload: project });
      },
      (error: string) => {
        message.error(error);
      }
    );
  };

  const loadTemplates = () => {
    setLoading(true);
    templateClient.getTemplates(projectId, currentPage, limit).then(
      (res: any) => {
        setTotal(res.total);
        setLoading(false);
        setTemplates(res.data);
        setHasLoaded(true);
      },
      (error: string) => {
        setLoading(false);
        message.error(error);
      }
    );
  };

  const paginatorChange = (page: number, pageSize: number) => {
    console.log(page, pageSize);
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const onCreateCollection = (collection: any) => {
    setShowCreateCollection(false);
    setShowUpdateColleciton(false);
    dispatch({ type: Actions.UPDATE_TEMPLATE, payload: collection });
    navigate(`/collection/${collection.id}/selector`);
  };

  const onUpdateCollection = (collection: any) => {
    setShowUpdateColleciton(false);
    templates.map((t: any) => {
      if (t.id === collection.id) t.name = collection.name;
      t.pages = collection.pages;
    });
    setTemplates([...templates]);
    setCollectionToUpdate(null);
  };

  const updateCollection = (collection: any) => {
    setCollectionToUpdate(collection);
    setShowUpdateColleciton(true);
  };

  const deleteCollection = (collection: any) => {
    setCollectionToUpdate(collection);
    setShowDeleteCollection(true);
  };

  const onDeleteCollection = (collection: any) => {
    let c = templates.filter((col: any) => {
      return col.id !== collection.id;
    });
    setTemplates(c);
    setShowDeleteCollection(false);
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main">
        <div className="page-padding">
          <div className="container">
            <div className="padding-vertical-small">
              <div
                className="page-heading-wrapper"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h2 className="page-heading">
                  <Link to="/">Projects</Link>
                  <span>&gt;</span>
                  {state.project && <span>{state.project.name}</span>}
                </h2>
                <a
                  href="#"
                  className="main-button w-button create-project"
                  onClick={() => setShowCreateCollection(true)}
                >
                  Create Collection
                </a>
              </div>
              <div className="repository-grid-wrapper source-grid ">
                <Pagination
                  current={currentPage}
                  pageSize={limit}
                  pageSizeOptions={[10, 20, 30, 50]}
                  showSizeChanger={true}
                  total={total}
                  onChange={paginatorChange}
                />
                <div className="repository-grid-navigation-wrapper collection-repository-wrapper">
                  <div className="repository-name-wrapper-w-padding">
                    <div className="page-grid-text">Collection name</div>
                  </div>
                  <div className="page-grid-text"># of columns</div>
                  <div className="page-grid-text"># of pages</div>
                  <div className="page-grid-text"></div>
                </div>
                <Skeleton active loading={loading}>
                  {templates.map((collection: any, index: number) => {
                    return (
                      <CollectionItem
                        key={collection.id}
                        collection={collection}
                        index={index}
                        updateCollection={updateCollection}
                        deleteCollection={deleteCollection}
                      />
                    );
                  })}
                </Skeleton>
                <Pagination
                  current={currentPage}
                  pageSize={limit}
                  pageSizeOptions={[10, 20, 30, 50]}
                  showSizeChanger={true}
                  total={total}
                  onChange={paginatorChange}
                />
              </div>
              {/* <a href="#" className="add-source-link w-inline-block">
            <div>Add Source</div>
          </a> */}
            </div>
          </div>
          {state.project && (
            <CreateCollectionModal
              project={state.project}
              show={showCreateCollection}
              onClose={() => setShowCreateCollection(false)}
              onCreate={onCreateCollection}
            />
          )}
          {state.project && (
            <UpdateCollectionModal
              project={state.project}
              show={showUpdateCollection}
              onClose={() => setShowUpdateColleciton(false)}
              onCreate={onUpdateCollection}
              collection={collectionToUpdate}
            />
          )}
          {state.project && (
            <DeleteCollectionModal
              show={showDeleteCollection}
              project={state.project}
              collection={collectionToUpdate}
              onClose={() => setShowDeleteCollection(false)}
              onDelete={onDeleteCollection}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Collections;
