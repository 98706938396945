import BaseClient from "./BaseClient";

class Selector extends BaseClient {
  getAllSelectors = (templateId: any, limit: number) => {
    let params = { limit };

    return this.get(`/template/${templateId}/selector`, { params });
  }

  addSelector = (templateId: number, data: any) => {
    return this.post(`/template/${templateId}/selector`, data);
  }

  updateSelector = (templateId: number, selectorId: number, data: any) => {
    return this.put(`/template/${templateId}/selector/${selectorId}`, data);
  }

  deleteSelector = (templateId: any, selectorId: number) => {
    return this.delete(`/template/${templateId}/selector/${selectorId}`);
  }
}

export default Selector;