import React, { useContext } from "react";
import { AppDispatchContext } from "@/components/context/AppContext";
import TemplateClient from "@/clients/TemplateClient";
import { message } from "antd";
import { EyeOutlined, SyncOutlined, DeleteOutlined } from "@ant-design/icons";
import { Actions } from "../context/AppReducer";
import { useNavigate } from "react-router-dom";

interface CollectionItemProps {
  collection: any;
  index: number;
  updateCollection: any;
  deleteCollection: any;
}

const CollectionItem: React.ComponentType<CollectionItemProps> = ({
  collection,
  index,
  updateCollection,
  deleteCollection,
}) => {
  const templateClient = new TemplateClient();
  const { dispatch } = useContext(AppDispatchContext);
  const navigate = useNavigate();

  const goToSelector = (e: any) => {
    console.log(e.target.tagName);
    if (e.target.classList.contains("action-button") || e.target.tagName === "path" || e.target.tagName === "svg") {
      e.preventDefault();
      return;
    }

    templateClient.getTemplateLinks(collection.id).then(
      (res: any) => {
        let links: Array<any> = [];
        res.forEach((link: any) => {
          links.push(link.link);
        });
        dispatch({ type: Actions.UPDATE_TEMPLATE, payload: collection });
        dispatch({ type: Actions.UPDATE_LINKS, payload: links });
        navigate(`/collection/${collection.id}/selector`, { replace: true });
      },
      (error: string) => {
        message.error(error);
      }
    );
  };

  const previewCollection = (collection: any) => {
    navigate(`/collection/${collection.id}/preview`, { replace: true });
  };

  return (
    <div
      className={`repository-single-item-wrapper collection-single-wrapper is-grey`}
      onClick={goToSelector}
    >
      <div className="repository-name-wrapper-w-padding">
        <div className="page-grid-item-text">{collection.name}</div>
      </div>
      <div className="repository-name-wrapper">
        <div className="page-grid-item-text">{collection.columns}</div>
      </div>
      <div className="repository-name-wrapper">
        <div className="page-grid-item-text">{collection.pages}</div>
      </div>
      <div className="repository-name-wrapper">
        <div className="page-grid-item-text item-action">
          <a
            href="#"
            className="action-button"
            onClick={() => previewCollection(collection)}
          >
            <EyeOutlined /> View
          </a>
          <a
            href="#"
            className="action-button"
            onClick={() => updateCollection(collection)}
          >
            <SyncOutlined /> Update
          </a>
          <a
            href="#"
            className="action-button"
            onClick={() => deleteCollection(collection)}
          >
            <DeleteOutlined className="action-button" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default CollectionItem;
