import React, { useContext, useEffect, useState } from "react";
import { Input, Select, Button, message, Popconfirm, Spin } from "antd";
import Selector from "../../clients/SelectorClient";
import Template from "../../clients/TemplateClient";
import { AppStateContext } from "../context/AppContext";
import { useParams } from "react-router-dom";
import Echo from "laravel-echo";
import JWTHelper from "@/utilities/JWTHelper";
import {
  DeleteOutlined,
  ReadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import GuideModal from "./GuideModal";

const Pusher = require("pusher-js");

interface ToolsProps {
  selected: string;
  elemselector: string;
}

const Tools: React.ComponentType<ToolsProps> = ({ selected, elemselector }) => {
  const selectorClient = new Selector();
  const templateClient = new Template();
  const { collectionId } = useParams();
  const { state } = useContext(AppStateContext);
  const [name, setName] = useState("");
  const [type, setType] = useState("html");
  const [selector, setSelector] = useState("");
  const [content, setContent] = useState("");
  const [elements, setElements] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [echo, setEcho] = useState<Echo | null>();
  const [isListening, setIsListening] = useState(false);
  const [elemToEdit, setElemToEdit] = useState<any>();
  const [updateSelector, setUpdateSelector] = useState(true);
  const [showGuide, setShowGuide] = useState(false);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 14, color: "#ffffff" }} spin />
  );

  const typeOptions = [
    { value: "title", label: "Title" },
    { value: "description", label: "Description" },
    { value: "ogtags", label: "Open Graph" },
    { value: "ogimage", label: "Open Graph Image" },
    { value: "twittertags", label: "Twitter Meta Tags" },
    { value: "text", label: "Text" },
    { value: "html", label: "HTML" },
    { value: "link", label: "Link" },
    { value: "text-group", label: "Text Group" },
    { value: "html-group", label: "HTML Group" },
    { value: "link-group", label: "Link Group" },
    { value: "image", label: "Image" },
    { value: "image-group", label: "Image Group" },
  ];

  useEffect(() => {
    selectorClient.getAllSelectors(collectionId, 30).then(
      (res: any) => {
        setElements(res.data);
      },
      (error: string) => {
        message.error(error);
      }
    );
  }, []);

  useEffect(() => {
    if (elemselector) setSelector(elemselector);
  }, [elemselector]);

  useEffect(() => {
    // console.log(selector);
    if (selector) {
      let frame = window.frames[0];
      if (frame) {
        // console.log(frame.window);
        frame.postMessage(selector, "*");
      }
    }
  }, [selector]);

  useEffect(() => {
    if (selected) {
      // console.log(selected);
      let tmp = document.createElement("div");
      tmp.innerHTML = selected;
      let element = tmp.childNodes[0];
      if (element) {
        setUpdateSelector(true);
        getNodeValue(element, selected);
      }
    }
  }, [selected]);

  useEffect(() => {
    // console.log(selected);
    let frame = window.frames[0];
    if (frame) {
      if (type === "title") {
        frame.postMessage({ type: "get_title" }, "*");
        setSelector("title")
      } else if (type === "description") {
        frame.postMessage({ type: "get_meta", meta: "description" }, "*");
        setSelector("meta[name='description']")
      } else if (type === "ogtags") {
        frame.postMessage({ type: "get_og_tags" }, "*");
        setSelector("meta[property*='og:']")
      } else if (type === "ogimage") {
        frame.postMessage({ type: "get_og_image" }, "*");
        setSelector("meta[property='og:image']")
      } else if (type === "twittertags") {
        frame.postMessage({ type: "get_twitter_tags" }, "*");
        setSelector("meta[property*='twitter:']")
      }
    }

    if (selected) {
      let tmp = document.createElement("div");
      tmp.innerHTML = selected;
      let element = tmp.childNodes[0];
      if (element) {
        setUpdateSelector(true);
        getNodeValue(element, selected);
      }
    }
  }, [type]);

  const saveElements = () => {
    if (loading) return;

    if (!state.template) {
      message.error("No selected template.");
      return;
    }

    if (name.length === 0) {
      message.error("Please enter name.");
      return;
    }

    let elem = {
      name: name,
      type: type,
      selector: selector,
      content:
        content && typeof content === "string"
          ? content.replace(/[\n\r]/g, "")
          : content,
      template_id: state.template.id,
    };

    setLoading(true);
    if (elemToEdit) {
      selectorClient
        .updateSelector(state.template.id, elemToEdit.id, elem)
        .then(
          (selector: any) => {
            setLoading(false);
            let index = elements.findIndex((e) => {
              return e.id === selector.id;
            });

            elements[index] = selector;
            setElements([...elements]);
            setName("");
            setContent("");
            setSelector("");
            setElemToEdit(null);
          },
          (error: string) => {
            message.error(error);
            setLoading(false);
          }
        );
    } else {
      selectorClient.addSelector(state.template.id, elem).then(
        (selector: any) => {
          setLoading(false);
          elements.push(selector);
          setElements([...elements]);
          setName("");
          setContent("");
          setSelector("");
        },
        (error: string) => {
          message.error(error);
          setLoading(false);
        }
      );
    }
  };

  const editElement = (elem: any) => {
    setUpdateSelector(false);
    setName(elem.name);
    setType(elem.type);
    setSelector(elem.selector);
    setContent(elem.content);
    setElemToEdit(elem);

    if (elem.selector) {
      let frame = window.frames[0];
      if (frame) {
        // console.log(frame.window);
        frame.postMessage(elem.selector, "*");
      }
    }
  };

  const deleteSelector = (selector: any, index: number) => {
    selectorClient.deleteSelector(collectionId, selector.id).then(
      (res: any) => {
        elements.splice(index, 1);
        setElements([...elements]);
        setElemToEdit(null);
        setName("");
        setContent("");
        setSelector("");
      },
      (error: string) => {
        message.error(error);
      }
    );
  };

  const resetFields = () => {
    setElemToEdit(null);
    setName("");
    setContent("");
    setSelector("");
  };

  const downloadCSV = () => {
    if (elements.length === 0) {
      message.error("No elements to download.");
      return;
    }

    setDownloadLoading(true);
    templateClient.generateCSV(state.template.id).then(
      (res: any) => {
        // console.log(res);
        // var element = document.createElement("a");
        // element.href = process.env.REACT_APP_BASE_URL + res.url;
        // element.target = "_blank";
        // element.download = "elements.csv";
        // element.click();
        // setDownloadLoading(false);
      },
      (error: string) => {
        setDownloadLoading(false);
        message.error(error);
      }
    );
  };

  const getNodeValue = (element: any, original: any) => {
    // console.log(element);
    var nodeType = type;
    var nodeVal: any = "";
    var nodeSelector = selector;
    if (element) {
      nodeSelector = element.nodeName.toLowerCase();
      nodeSelector = nodeSelector + (element.id ? "#" + element.id : "");
      if (element)
        nodeSelector =
          nodeSelector +
          (element.classList && element.classList.length
            ? "." + element.classList.value.replace(" ", ".")
            : "");
      // if (updateSelector) setSelector(nodeSelector);
    }

    // if (!document.querySelector(nodeSelector)) {
    //   message.error("The selector can not be found on the page");
    //   return false;
    // }
    switch (nodeType) {
      case "text":
        nodeVal = element ? element.textContent : "";
        break;
      case "html":
        nodeVal = element ? element.outerHTML : "";
        break;
      case "description":
        console.log(element);
        nodeVal = element ? element.getAttribute("content") : "";
        break;
      case "link":
        // nodeVal = element.href || element.children[0].href;
        nodeVal = element.href;
        if (!nodeVal) {
          let a = element.getElementsByTagName("a");
          if (a && a.length) nodeVal = a[0].href;
        }
        break;
      case "link-group":
        nodeVal = original.map((el: any) => el.textContent);
        break;
      case "twittertags":
      case "ogtags":
      case "html-group":
        // nodeVal = [...element.querySelectorAll("a")].map((el: any) => el.href);
        console.log(original);
        if (original.length > 0) {
          nodeVal = original.join(",");
        }
        break;
      case "link-group":
        nodeVal = [...element.querySelectorAll("a")].map((el: any) => el.href);
        break;
      case "image":
        nodeVal = element.src;
        if (!nodeVal) {
          let img = element.getElementsByTagName("img");
          if (img && img.length) nodeVal = img[0].src;
        }
        break;
      case "image-group":
        nodeVal = [...element.querySelectorAll("img")].map((el: any) => el.src);
        break;
      default:
        nodeVal = element.textContent;
        break;
    }
    setContent(nodeVal);
    // $(".page-selector").val(nodeSelector);
    // $(".page-value").val(nodeVal ? nodeVal : `no ${nodeType}`);
    // $(nodeSelector).first().addClass("selected-el");
  };

  return (
    <div className="tools-container">
      <h4>Create a collection column</h4>
      {/* <div dangerouslySetInnerHTML={{ __html: selected }}></div> */}
      <div className="form w-form">
        <div className="input">
          <label className="popup-modal-field-label">Column name</label>
          <Input
            className="popup-modal-input-field w-input"
            type="text"
            placeholder="Name..."
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="input">
          <label className="popup-modal-field-label">Content to select</label>
          <Select
            className="popup-modal-input-field w-input"
            value={type}
            onChange={(val) => setType(val)}
            options={typeOptions}
            style={{ width: "100%" }}
          />
        </div>
        <div className="input">
          <label className="popup-modal-field-label">Css selector</label>
          <Input.TextArea
            className="popup-modal-input-field w-input"
            rows={3}
            placeholder="Selector..."
            value={selector}
            onChange={(e) => setSelector(e.target.value)}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
          <Button
            type="link"
            icon={<ReadOutlined />}
            onClick={() => setShowGuide(true)}
            style={{ fontWeight: 500, paddingLeft: 0, marginTop: 10 }}
          >
            Open selector guide
          </Button>
        </div>
        <p className="input">
          <label className="popup-modal-field-label">Selected content</label>
          <Input.TextArea
            className="popup-modal-input-field w-input"
            rows={4}
            placeholder="Content..."
            value={content}
            onChange={(e) => setContent(e.target.value)}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </p>
        <div className="input" style={{ display: "flex" }}>
          <a
            href="#"
            className="main-button w-button create-project"
            onClick={saveElements}
          >
            {elemToEdit ? `Edit column` : `Create column`}{" "}
            {loading && <Spin indicator={antIcon} />}
          </a>
          <a
            href="#"
            onClick={resetFields}
            className="main-button w-button reset"
            style={{ fontWeight: 500 }}
          >
            Reset
          </a>
        </div>
      </div>
      <div className="separator"></div>
      <div className="elements-list">
        <h3>Elements</h3>
        {elements.map((elem, i) => {
          return (
            <div
              key={i}
              className="element"
              style={{
                borderColor:
                  elemToEdit && elemToEdit.id === elem.id
                    ? "#1169FE"
                    : "#555555",
              }}
            >
              <a
                href="#"
                onClick={() => editElement(elem)}
                style={{
                  color:
                    elemToEdit && elemToEdit.id === elem.id
                      ? "#1169FE"
                      : "#555555",
                }}
              >
                {elem.name}: {elem.selector}
              </a>
              <a
                href="#"
                className="delete-selector"
                style={{
                  color:
                    elemToEdit && elemToEdit.id === elem.id
                      ? "#1169FE"
                      : "#555555",
                }}
              >
                <Popconfirm
                  placement="topRight"
                  title="Are you sure to delete this?"
                  onConfirm={() => deleteSelector(elem, i)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined />
                </Popconfirm>
              </a>
            </div>
          );
        })}
      </div>
      <GuideModal show={showGuide} onClose={() => setShowGuide(false)} />
    </div>
  );
};

export default Tools;
