import { User } from "@/models/User";

export interface State {
  links: Array<string>,
  showUpload: boolean,
  template: any,
  project: any,
  currentPage: any
  currentUser: User | null
}

export const Actions = {
  UPDATE_LINKS: "update_links",
  UPDATE_SHOW_UPLOAD: "update_show_upload",
  UPDATE_CURRENT_PAGE: "update_current_page",
  UPDATE_TEMPLATE: "update_template",
  UPDATE_PROJECT: "update_project",
  UPDATE_USER: "update_user",
};

export const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case Actions.UPDATE_LINKS:
      return {
        ...state,
        links: action.payload,
      }
    case Actions.UPDATE_SHOW_UPLOAD:
      return {
        ...state,
        showUpload: action.payload
      }
    case Actions.UPDATE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload
      }
    case Actions.UPDATE_TEMPLATE:
      return {
        ...state,
        template: action.payload
      }
    case Actions.UPDATE_PROJECT:
      return {
        ...state,
        project: action.payload
      }
    case Actions.UPDATE_USER:
      return {
        ...state,
        currentUser: action.payload
      }
    default:
      return { ...state };
  }
};
