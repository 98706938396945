import React, { useState } from "react"
import Modal from "@/components/common/Modal";
import Button from "@/components/common/Button"
import TemplateClient from "@/clients/TemplateClient";
import { message } from "antd"

interface DeleteCollectionModalProps {
  show: boolean
  onClose: any
  collection: any
  project: any
  onDelete: any
}

const DeleteCollectionModal: React.ComponentType<DeleteCollectionModalProps> = ({
  show,
  onClose,
  collection,
  project,
  onDelete,
}) => {
  const templateClient = new TemplateClient();
  const [loading, setLoading] = useState(false)

  const deleteCollection = () => {
    setLoading(true)
    templateClient.deleteTemplate(project.id, collection.id!).then(
      () => {
        onDelete(collection)
        setLoading(false)
      },
      (error) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  return (
    <Modal show={show} onClose={onClose}>
      <div className="margin-bottom-30">
        <div>Are you sure you want to delete this collection?</div>
      </div>

      <Button
        className="delete-project-button w-button"
        label="Yes, delete"
        danger
        onClick={deleteCollection}
        fontSize={24}
        loading={loading}
      />
      <a href="#" className="cancel-link" onClick={onClose}>
        No, cancel
      </a>
    </Modal>
  )
}

export default DeleteCollectionModal
