import React, { useContext, useEffect, useState } from "react";
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/context/AppContext";
import { Actions } from "../context/AppReducer";
import Template from "../../clients/TemplateClient";
import { Link, useParams } from "react-router-dom";
import { message, Select } from "antd";
import { EyeOutlined } from "@ant-design/icons";

const LinkList: React.ComponentType = () => {
  const templateClient = new Template();
  const { state } = useContext(AppStateContext);
  const { dispatch } = useContext(AppDispatchContext);
  const { collectionId } = useParams();
  const [selectedLink, setSelectedLink] = useState();

  useEffect(() => {
    templateClient.getTemplate(collectionId).then((template: any) => {
      dispatch({ type: Actions.UPDATE_TEMPLATE, payload: template });
    });

    templateClient.getTemplateLinks(collectionId).then(
      (res: any) => {
        let links: Array<any> = [];
        res.forEach((link: any) => {
          links.push(link.link);
        });
        dispatch({ type: Actions.UPDATE_LINKS, payload: links });
        setSelectedLink(links[0]);
      },
      (error: any) => {
        message.error(error);
      }
    );
  }, []);

  useEffect(() => {
    if (selectedLink) {
      linkClick(selectedLink);
    }
  }, [selectedLink]);

  // useEffect(() => {
  //   if (state.template) createLink();
  // }, [state.template]);

  const createLink = () => {
    if (state.links) {
      templateClient
        .saveLinks(state.template.id!, state.links)
        .then((res: any) => {
          // console.log(res);
        });
    }
  };

  const linkClick = (link: any) => {
    let localLink = `${process.env.REACT_APP_API_BASE_URL}/scrape/url?link=${link}`;
    dispatch({ type: Actions.UPDATE_CURRENT_PAGE, payload: localLink });
  };

  const truncateUrl = (url: string, length?: number) => {
    if (!length) length = 50;

    if (url.length > 32) {
      let stringLength = length - 4; //leght of each side of the string
      let newUrl = "..." + url.substring(url.length - stringLength, url.length);
      return newUrl;
    }

    return url;
  };

  return (
    <div style={{ paddingLeft: 40, paddingRight: 40 }}>
      <div className="link-list form w-form">
        {state.template && (
          <div className="link-list-links">
            <Link to={`/project/${state.template.project_id}/collections`}>
              &lt; Back to collections list
            </Link>
            <Link to={`/collection/${state.template.id}/preview`}>
              View &gt; 
            </Link>
          </div>
        )}
        {state.template && <h4>{state.template.name}</h4>}
        <h4>Page Link</h4>
        {/* {state.links.map((link, i) => {
        return (
          <a href="#" key={i} onClick={() => linkClick(link)}>
            {truncateUrl(link,  30)}
          </a>
        );
      })} */}
        <div className="select-link">
          <Select
            className="popup-modal-input-field w-input"
            showSearch
            value={selectedLink}
            onChange={(val) => setSelectedLink(val)}
            filterOption={(input, option: any) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {state.links.map((link: string, i: number) => {
              return (
                <Select.Option key={i} value={link}>
                  {truncateUrl(link, 30)}
                </Select.Option>
              );
            })}
          </Select>
          <a href={selectedLink} target="_blank" style={{ padding: 0 }}>
            <EyeOutlined />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LinkList;
