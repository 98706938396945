import React, { useState } from "react";
import Modal from "../common/Modal";
import Button from "../common/Button";
import ProjectClient from "../../clients/ProjectClient";
import { message, Spin } from "antd";
import {
  LoadingOutlined,
  UploadOutlined
} from "@ant-design/icons";

interface CreateProjectModalProps {
  show: boolean;
  onClose: any;
  onCreate: any;
}

const CreateProjectModal: React.ComponentType<CreateProjectModalProps> = ({
  show,
  onClose,
  onCreate,
}) => {
  const projectClient = new ProjectClient();
  const [projectName, setProjectName] = useState("");
  const [loading, setLoading] = useState(false);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 14, color: "#ffffff" }} spin />
  );

  const createProject = () => {
    setLoading(true);
    projectClient.createProject({ name: projectName }).then(
      (project: any) => {
        onCreate(project);
        setProjectName("");
        setLoading(false);
      },
      (error: string) => {
        message.error(error);
        setLoading(false);
      }
    );
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="margin-bottom-40">
        <h2 className="page-heading">Start a new project</h2>
      </div>
      <div className="popup-modal-form-block w-form">
        <form
          id="start-new-project"
          name="wf-form-Start-new-project"
          data-name="Start new project"
          method="get"
        >
          <div className="margin-bottom-30">
            <label className="popup-modal-field-label">Project Name</label>
            <input
              type="text"
              className="popup-modal-input-field w-input"
              name="Project-Name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              data-name="Project Name"
              placeholder="Enter project name"
              id="Project-Name"
            />
          </div>
          {/* <Button
            label="Create Project"
            fontSize={24}
            loading={loading}
            onClick={createProject}
          /> */}
          <a
            href="#"
            className="main-button w-button create-project"
            onClick={createProject}
          >
            Create Project {loading && <Spin indicator={antIcon} />}
          </a>
        </form>
      </div>
    </Modal>
  );
};

export default CreateProjectModal;
