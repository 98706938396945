import React, { useState } from "react";
import LinkList from "@/components/tools/LinkList";
import Tools from "@/components/tools/Tools";
import Content from "@/components/content/Page";
import Header from "../common/Header";

const Selector: React.ComponentType = () => {
  const [selected, setSelected] = useState("");
  const [selector, setSelector] = useState("");

  return (
    <React.Fragment>
      <Header />
      <div className="main">
        <div className="preview-container">
          <div className="page-padding selector">
            <div className="container selector">
              <div className="container selector selector-container">
                <div className="left-sidebar">
                  <LinkList />
                  <Tools selected={selected} elemselector={selector} />
                </div>
                <div className="right-content">
                  <Content setSelected={setSelected} setSelector={setSelector}>
                    <div
                      className="inspector-element"
                      style={{ position: "absolute" }}
                    ></div>
                  </Content>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Selector;
