import React, { useContext, useEffect, useState } from "react";
import TemplateCliemt from "@/clients/TemplateClient";
import ProjectClient from "@/clients/ProjectClient";
import { useParams, Link } from "react-router-dom";
import { message, Button, Table, Spin, Pagination } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AppStateContext, AppDispatchContext } from "../context/AppContext";
import Echo from "laravel-echo";
import JWTHelper from "@/utilities/JWTHelper";
import Header from "../common/Header";
import { Actions } from "../context/AppReducer";

const TemplatePreview = () => {
  const { collectionId } = useParams();
  const templateClient = new TemplateCliemt();
  const projectClient = new ProjectClient();
  const { state } = useContext(AppStateContext);
  const { dispatch } = useContext(AppDispatchContext);
  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState([]);
  const [collection, setColleciton] = useState<any>();
  const [tableHeader, setTableHeader] = useState<Array<object>>([]);
  const [tableData, setTableData] = useState<Array<object>>([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [echo, setEcho] = useState<Echo | null>();
  const [isListening, setIsListening] = useState(false);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 14, color: "#ffffff" }} spin />
  );

  useEffect(() => {
    initializeEcho();
  }, []);

  useEffect(() => {
    if (collection && !state.project) loadProject();
  }, [collection]);

  useEffect(() => {
    if (hasLoaded) loadCollections();
  }, [currentPage]);

  useEffect(() => {
    if (hasLoaded) {
      setCurrentPage(1);
      loadCollections();
    }
  }, [limit]);

  const loadProject = () => {
    projectClient.getProject(collection.project_id).then(
      (project: any) => {
        dispatch({ type: Actions.UPDATE_PROJECT, payload: project });
      },
      (error: string) => {
        message.error(error);
      }
    );
  };

  useEffect(() => {
    if (!hasLoaded) {
      templateClient.getTemplate(collectionId).then(
        (col: any) => {
          setColleciton(col);
          loadCollections();
        },
        (error: string) => {
          message.error(error);
          setLoading(false);
        }
      );
      setHasLoaded(true);
    }
  }, [hasLoaded]);

  const initializeEcho = () => {
    if (echo !== undefined)
      // don't re-initiliaze echo so it will not send multiple notifications
      return;

    let client = new Echo({
      broadcaster: "pusher",
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: false,
      authEndpoint: process.env.REACT_APP_API_BASE_URL + "/broadcasting/auth",
      auth: {
        headers: { Authorization: "Bearer " + JWTHelper.getAccessToken() },
      },
    });
    setEcho(client);
  };

  useEffect(() => {
    if (echo === undefined || isListening) return;

    let filechannel = echo!
      .private(`template.${collectionId}.csvgenerated`)
      .listen(".csv.generated", (e: any) => {
        console.log(e)
        if (e.fileurl && e.templateId === parseInt(collectionId!) && e.userId === parseInt(JWTHelper.getUserId())  ) {
          var element = document.createElement("a");
          element.href = e.fileurl;
          element.target = "_blank";
          element.download = "elements.csv";
          element.click();
          setDownloadLoading(false);
        }
      });

    setIsListening(true);

    return () => {
      filechannel.stopListening(".csv.generated");
    };
  }, [echo]);

  const loadCollections = () => {
    setLoading(true);
    setTableData([]);
    templateClient.previewSelectors(collectionId, limit, currentPage).then(
      (data: any) => {
        let elements = data.data;
        setTotal(data.total);
        setElements(elements);
        setLoading(false);
        let tData: Array<object> = [];
        elements.map((values: any, index: number) => {
          if (index === 0) {
            values.map((val: string, i: number) => {
              let exists = tableHeader.find((h: any) => {
                return h.title === val;
              });
              if (!exists) {
                let header = {
                  title: val,
                  dataIndex: i,
                  key: i,
                };
                tableHeader.push(header);
                setTableHeader([...tableHeader]);
              }
            });
          } else {
            let data: any = { key: index };
            values.map((val: string, i: number) => {
              data[i] = truncateString(val, 100);
            });
            tData.push(data);
          }
        });
        setTableData([...tData]);
        // console.log(tableHeader);
      },
      (error: string) => {
        message.error(error);
        setLoading(false);
      }
    );
  };

  const truncateString = (url: string, length?: number) => {
    if (!length) length = 50;

    if (url.length > length) {
      let stringLength = length - 4; //leght of each side of the string
      let newUrl = "..." + url.substring(url.length / 2, url.length);
      return newUrl;
    }

    return url;
  };

  const paginatorChange = (page: number, pageSize: number) => {
    // console.log(page, pageSize);
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const downloadCSV = () => {
    if (downloadLoading) return;

    if (elements.length === 0) {
      message.error("No elements to download.");
      return;
    }

    setDownloadLoading(true);
    templateClient.generateCSV(collectionId).then(
      (res: any) => {
        // console.log(res);
        // var element = document.createElement("a");
        // element.href = process.env.REACT_APP_BASE_URL + res.url;
        // element.target = "_blank";
        // element.download = "elements.csv";
        // element.click();
        // setDownloadLoading(false);
      },
      (error: string) => {
        setDownloadLoading(false);
        message.error(error);
      }
    );
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main">
        <div className="preview-container">
          <div className="page-padding">
            <div className="container">
              <div className="padding-vertical-small">
                <div
                  className="page-heading-wrapper"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h2 className="page-heading">
                    <b>
                      <Link to="/">Projects</Link>
                    </b>
                    <span>&gt;</span>
                    <b>
                      {collection && state.project && (
                        <Link
                          to={`/project/${collection.project_id}/collections`}
                        >
                          {state.project && <span>{state.project.name}</span>}
                        </Link>
                      )}
                    </b>
                    <span>&gt;</span>
                    {collection && <span>{collection.name}</span>}
                  </h2>
                  {/* <Button
                    type="primary"
                    loading={downloadLoading}
                    onClick={downloadCSV}
                    style={{ marginLeft: 15 }}
                  >
                    Download CSV
                  </Button> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Pagination
                      current={currentPage}
                      pageSize={limit}
                      pageSizeOptions={[10, 20, 30, 50]}
                      showSizeChanger={true}
                      total={total}
                      onChange={paginatorChange}
                    />
                    <a
                      style={{ marginLeft: 20 }}
                      href="#"
                      className="main-button w-button create-project"
                      onClick={downloadCSV}
                    >
                      Download CSV{" "}
                      {downloadLoading && <Spin indicator={antIcon} />}
                    </a>
                  </div>
                </div>
                <div className="repository-grid-wrapper source-grid ">
                  <Table
                    columns={tableHeader}
                    dataSource={tableData}
                    loading={loading}
                    pagination={{
                      current: currentPage,
                      pageSize: limit,
                      pageSizeOptions: [10, 20, 30, 50],
                      showSizeChanger: true,
                      onChange: paginatorChange,
                      total: total,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TemplatePreview;
