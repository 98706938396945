import React, {
  createContext,
  Dispatch,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Actions, reducer, State } from "./AppReducer";
import UserClient from "@/clients/UserClient";
import { User } from "@/models/User";

const initialState: State = {
  links: [],
  showUpload: true,
  currentPage: null,
  template: null,
  project: null,
  currentUser: null,
};

interface IAppStateContext {
  state: State;
}

interface IDispatchContext {
  dispatch: Dispatch<any>;
}

export const AppStateContext = createContext<IAppStateContext>({
  state: initialState,
});
export const AppDispatchContext = createContext<IDispatchContext>({
  dispatch: () => {},
});

export const AppProvider: React.ComponentType<any> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const userClient = new UserClient();

  useEffect(() => {
    userClient.getCurrentUser().then((user: User) => {
      dispatch({ type: Actions.UPDATE_USER, payload: user });
    });
  }, []);

  return (
    <AppStateContext.Provider value={{ state }}>
      <AppDispatchContext.Provider value={{ dispatch }}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};
