import React, { useState } from "react";
import logo from "./logo.svg";
import "./resources/css/wes-frontend.webflow.css";
import "./App.css";
import UploadCsv from "@/components/pages/UploadCsv";
import Home from "@/components/pages/Home";
import Collections from "@/components/pages/Collections";
import Selector from "@/components/pages/Selector";
import Login from "@/components/pages/Login";
import TemplatePreview from "@/components/pages/TemplatePreview";
import { AppProvider } from "@/components/context/AppContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  const [showUpload, setShowUpload] = useState(true);

  return (
    <BrowserRouter>
      <AppProvider>
        <div className="App">
          {/* <PageHeader title={<a href="/">MAT</a>} subTitle="Migration Tool" extra={<Logout />}> */}
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Home />}></Route>
              <Route path="/project/:projectId/collections" element={<Collections />}></Route>
              <Route path="/collection/:collectionId/selector" element={<Selector />}></Route>
              <Route path="/collection/:collectionId/preview" element={<TemplatePreview />}></Route>
            </Routes>
          {/* </PageHeader> */}
        </div>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
