import UserClient from "@/clients/UserClient";
import { User, UserToken } from "@/models/User";
import JWTHelper from "@/utilities/JWTHelper";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { message, Button, Spin } from "antd";
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/context/AppContext";
// import AccessRequestClient from "@/clients/AccessRequestClient"
// import { Team, TeamMember } from "@/models/Project"
// import TeamClient from "@/clients/TeamClient"
import { Actions } from "../context/AppReducer";
import {
  DeleteOutlined,
  ReadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

const Login: React.ComponentType = () => {
  let navigate = useNavigate();
  let userClient = new UserClient();
  // const teamClient = new TeamClient()
  // let requestClient = new AccessRequestClient()
  const { dispatch } = useContext(AppDispatchContext);

  const [showFail, setShowFail] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [requestEmail, setRequestEmail] = useState("");
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 14, color: "#ffffff" }} spin />
  );

  useEffect(() => {
    if (JWTHelper.isLogin()) navigate("/", { replace: true });
  });

  const login = (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);
    userClient
      .login(username, password)
      .then(
        (token: UserToken) => {
          JWTHelper.storeToken(token);
          userClient.getCurrentUser().then(
            (user: User) => {
              // teamClient.getTeams().then((team: TeamMember) => {
              //   if (team) {
              //     dispatch({ type: Actions.UPDATE_TEAM, payload: team })
              //   }
              // })
              dispatch({ type: Actions.UPDATE_USER, payload: user });
              navigate("/", { replace: true });
            },
            (error) => {
              setShowFail(true);
              // message.error(error);
            }
          );
        },
        (error: string) => {
          setShowFail(true);
          // message.error(error);
        }
      )
      .finally(() => setLoading(false));
  };

  // const sendRequest = () => {
  //   if (requestEmail.length === 0) {
  //     message.error("Please enter your email.")
  //     return
  //   }

  //   setLoading(true)
  //   requestClient.sendEmail(requestEmail).then(
  //     () => {
  //       setLoading(false)
  //       message.success("Your request has been sent.")
  //     },
  //     (error: string) => {
  //       setLoading(false)
  //       message.error(error)
  //     }
  //   )
  // }

  return (
    <div className="gradient-background">
      <div className="page-padding">
        <div className="padding-vertical">
          <div className="login-page-wrapper">
            <div className="login-page-left">
              <a href="#" className="logo-link w-inline-block">
                <div className="wes-logo">
                  MAT <span style={{ fontSize: 16 }}>Migration Tool</span>
                </div>
              </a>
              <div className="flex">
                <h1 className="login-main-heading">
                  Easily migrate your website content to Webflow CMS.
                </h1>
              </div>
            </div>
            <div className="login-page-right">
              <div
                data-current="Tab 1"
                data-easing="ease"
                data-duration-in="300"
                data-duration-out="100"
                className="login-tabs w-tabs"
              >
                <div className="login-tabs-menu w-tab-menu">
                  <a
                    data-w-tab="Tab 1"
                    className={`login-tabs-link w-inline-block w-tab-link ${
                      !showRegister ? "w--current" : ""
                    }`}
                    onClick={() => setShowRegister(false)}
                  >
                    <div>Sign In</div>
                  </a>
                  <a
                    data-w-tab="Tab 2"
                    className={`login-tabs-link w-inline-block w-tab-link ${
                      showRegister ? "w--current" : ""
                    }`}
                    onClick={() => setShowRegister(true)}
                  >
                    <div>Request Access</div>
                  </a>
                </div>
                <div className="login-tab-content w-tab-content">
                  <div
                    data-w-tab="Tab 1"
                    className={`login-tab-pane ${
                      !showRegister ? "w--tab-active" : ""
                    }`}
                  >
                    <div className="login-wrapper">
                      <h2 className="heading">Welcome</h2>
                      <div className="margin-bottom-40">
                        <div className="white-text">
                          Sign in to your account to manage projects.
                        </div>
                      </div>
                      <div className="form-block w-form login-form">
                        {showFail && (
                          <div className="w-form-fail">
                            <div>
                              Oops! Your user credentials are incorrect.
                            </div>
                          </div>
                        )}
                        <form
                          id="email-form"
                          name="email-form"
                          data-name="Email Form"
                          method="get"
                        >
                          <input
                            type="email"
                            className="input-field w-input"
                            autoComplete="off"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            maxLength={256}
                            name="Email"
                            data-name="Email"
                            placeholder="Email"
                            id="Email"
                          />
                          <input
                            type="password"
                            className="input-field is-password w-input"
                            autoComplete="off"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            maxLength={256}
                            name="Password"
                            data-name="Password"
                            placeholder="Password"
                            id="Password"
                            required
                          />
                          <label className="w-checkbox checkbox-field">
                            <input
                              type="checkbox"
                              id="checkbox"
                              name="checkbox"
                              data-name="Checkbox"
                              className="w-checkbox-input"
                            />
                            <span className="white-text w-form-label">
                              Remember me
                            </span>
                          </label>
                          {/* <Button
                            type="primary"
                            onClick={login}
                            loading={loading}
                            className="submit-button w-button"
                          >
                            Sign in
                          </Button> */}
                          <a
                            href="#"
                            className="main-button w-button create-project"
                            onClick={login}
                          >
                            Sign in
                            {loading && <Spin indicator={antIcon} />}
                          </a>
                          <div style={{marginTop: 20}}>
                            <div className="account-text">
                              Don’t have an account?  
                              <a
                                href="#"
                                className="sign-up-span"
                                onClick={() => setShowRegister(true)}
                              >
                                Request access
                              </a>
                              .
                            </div>
                          </div>
                        </form>
                        <div className="w-form-done">
                          <div>
                            Thank you! Your submission has been received!
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    data-w-tab="Tab 2"
                    className={`login-tab-pane ${
                      showRegister ? "w--tab-active" : ""
                    }`}
                  >
                    <div className="login-wrapper">
                      <div className="form-block center w-form">
                        <form
                          id="Request-Access"
                          name="wf-form-Request-Access"
                          data-name="Request Access"
                          method="get"
                        >
                          <h2 className="heading">Request Access</h2>
                          <div className="margin-bottom-40">
                            <div className="white-text">
                              Do you want to take control of your Weblow
                              deployment? We’ll be granting requests soon.
                            </div>
                          </div>
                          <input
                            type="email"
                            value={requestEmail}
                            onChange={(e) => setRequestEmail(e.target.value)}
                            className="input-field w-input"
                            maxLength={256}
                            name="Email-2"
                            data-name="Email 2"
                            placeholder="Email"
                            id="Email-2"
                          />
                          <Button
                            // onClick={sendRequest}
                            type="primary"
                            loading={loading}
                            className="submit-button w-button"
                          >
                            Join the waitlist
                          </Button>
                          {/* <input
                            type="submit"
                            data-wait="Please wait..."
                            value="Join the waitlist"
                            className="submit-button w-button"
                          /> */}
                          {/* <a
                            href="mailto:mason@edgarallan.com?cc=jared@edgarallan.com&?subject=Join%20the%20waitlist%20for%20Wes"
                            className="submit-button w-button"
                          >
                            Join the waitlist
                          </a> */}
                        </form>
                        <div className="request-access-success-message w-form-done">
                          <h2 className="heading">Thanks!</h2>
                          <div className="margin-bottom-40">
                            <div className="white-text">
                              We’re excited you want to join us! We’ll be in
                              touch real soon.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
