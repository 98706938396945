import React, { useContext } from "react";
import Logout from "@/components/login/Logout";
import grid from "@/resources/images/grid-1-1.svg"
import AccountSettings from "./AccountSettings";
import { AppStateContext } from "@/components/context/AppContext";

const Header = () => {
  const { state } = useContext(AppStateContext)
  return (
    <div>
      <div
        data-animation="default"
        data-collapse="small"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar-home w-nav"
      >
        <div className="navbar-container">
          <a href="/" className="w-nav-brand">
            <div className="navbar-wes-logo">
              MAT Migration Tool
              </div>
          </a>
          {/* {state.project && <a href={`/project/${state.project.id}/collections`} className="w-nav-brand">
            <div className="navbar-wes-logo">
              {state.project.name}
              </div>
          </a>} */}
          <nav role="navigation" className="navbar-menu w-nav-menu">
            <AccountSettings />
          </nav>
          <div className="w-nav-button">
            <div className="w-icon-nav-menu"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
