import React, { useContext, useEffect, useState } from "react";
import { AppStateContext } from "@/components/context/AppContext";
import axios from "axios";
import { createPortal } from "react-dom";
import { Spin } from "antd";

const Page: React.ComponentType<any> = ({
  children,
  setSelected,
  setSelector,
}) => {
  const [frameRef, setFrameRef] = useState<any>();
  const { state } = useContext(AppStateContext);
  const [frameloading, setFrameloading] = useState(false);

  useEffect(() => {
    window.addEventListener("message", (e) => {
      console.log(e.data);
      if (e.data.type && e.data.type === "element") {
        // console.log(e.data);
        setSelected(e.data.data);
        setSelector(e.data.selector);
      }
    });
  }, []);

  useEffect(() => {
    if (state.currentPage) {
      let frame = window.frames[0];
      if (frame) {
        // console.log(frame.window);
        
      }
    }
  }, [state.currentPage]);

  useEffect(() => {
    console.log(children);
    // setFrameloading(true);
    frameRef?.addEventListener("click", (e: any) => {
      // console.log(e);
    });

    // frameRef?.addEventListener("load", function(e: any) {
    //   console.log("Frame has loaded");
    //   setFrameloading(false);
    // });
  }, [frameRef]);

  const frameClick = (e: any) => {
    console.log(e);
  };

  const frameHovered = () => {
    let frame = window.frames[0];
    if (frame) {
      // console.log("Frame is hovered.");
      frame.postMessage({ type: "show_selector" }, "*");
    }
  };

  const frameNotHovered = () => {
    let frame = window.frames[0];
    if (frame) {
      // console.log("Mouse Leave the frame.");
      frame.postMessage({ type: "hide_selector" }, "*");
    }
  };

  return (
    <React.Fragment>
      {frameloading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i>
            <Spin spinning={frameloading}></Spin>
          </i>
        </div>
      )}

      <iframe
        style={{ display: frameloading ? "none" : "block" }}
        id="page-frame"
        src={state.currentPage}
        ref={(container: any) => setFrameRef(container)}
        onMouseEnter={frameHovered}
        onMouseLeave={frameNotHovered}
      >
        {createPortal(children, document.createElement("div"))}
      </iframe>

      {/* <div dangerouslySetInnerHTML={{__html: content!}}></div> */}
    </React.Fragment>
  );
};

export default Page;
